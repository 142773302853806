.red {
  color: red;
}

.reponsive-bottom-nav{
  padding: 0 11rem;
  min-width: 100%;
}

@media only screen and (max-width: 1600px) {
  .reponsive-bottom-nav{
    padding: 0 6rem;
  }
}

@media only screen and (max-width: 1300px) {
  .reponsive-bottom-nav{
    padding: 0;
  }
}

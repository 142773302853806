.bottom-nav-left{
  text-align: left;
}

.bottom-nav-right{
  text-align: right;
}

@media only screen and (max-width: 1000px) {
  .bottom-nav-left-responsive{
    text-align: center;
    width:100%;
    padding-left: 36px;
  }

  .bottom-nav-right-responsive{
    text-align: center;
    margin-top: 10px;
    width:100%;
    padding-right: 36px;
  }
}

.bottom-nav-left-responsive {
  padding-left: 36px;
}

.bottom-nav-right-responsive{
  padding-right: 36px;
}
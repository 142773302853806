.dashboard-logo {
  height: 30px;
  margin: 16px auto 16px auto;
  background: transparent;
  display: block;
}

.dashboard-layout {
  min-height: 100vh;
}

.login-form-card {
  width: 40vh;
  margin-left: auto;
  margin-right: auto;
  margin-top: 25vh;
  .ant-col {
    min-width: 100%;
  }
} 

.ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 8px;
}

.login-form {
  margin-left: auto;
  margin-right: auto;
}
.login-form-forgot {
  float: right;
}
.login-form-button {
  width: 100%;
}

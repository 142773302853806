.register-form-card {
  width: 70vh;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20vh;
} 

.steps-content {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 24px;
}

.steps-action {
  margin-top: 24px;
}

.register-form {
  width: 50vh;
  margin-left: auto;
  margin-right: auto;
}

.register-form-item {
  width: 50vh;
  margin-left: auto;
}

.register-form-button {
  position: relative;
  bottom: 0px;
  right: 0px;
}

.loading-spin-center {
  position: absolute;
  top: 50%;
  left: 50%;
}
